import React from 'react';
import { graphql, Link } from 'gatsby';
import { getKeyedResources } from '../../../utils/resource-helper';
import Layout from '../../../components/Layout';
import Section from '../../../components/Section';
import { Container, Row, Column } from '../../../components/Grid';
import Icon from '../../../components/Icon';
import Image from '../../../components/Image';
import Hero from '../../../components/Hero';
import SubfooterNav from '../../../components/SubfooterNav';
import Resource from '../../../components/Resource';
import bgImage from '../../../images/bg_ag_environmental_texture.jpg';
import '../../../styles/pages/_unit-sections.scss';

const AgEnvironmentalUnit6 = ({ data }) => {
  const heroBreadcrumbs = {
    unitLabel: 'UNIT 6',
    linkLabel: 'AG/ENVIRONMENTAL COURSE',
    to: '/classroom-instruction/ag-environmental/',
  };

  const {
    agAlternativeProteinsIntro,
    agIdentifyingGmos,
    agDetectingGeneticallyEngineeredCrops,
    agDnaToAlternativeProteins,
    agGoldenRiceCaseStudy,
    agBioethicsDebate,
    agIndustriesWithGeTechnology,
    agProjectRollout,
    agCommunityOutreach,
    agSustainabilityAndManufacturing,
    agAdCampaign,
  } = getKeyedResources(data);

  return (
    <Layout title="Ag-Environmental Unit 6">
      {/* -----------------UNIT BANNER----------------------- */}
      <Hero
        className={'hero--small course__hero'}
        modal
        imageSource="header-plant-unit6@2x.jpg"
        guidSrc="fec6b9d3-59b0-4e36-b4ba-35f3afc46431"
        posterSrc={'unit-6-agplant-intro-video-still.gif'}
        breadcrumbs={heroBreadcrumbs}
        color={'green'}
        playtext={'Video Intro'}
      >
        <h1 className=" mb-0 pb-0">Alternative Proteins</h1>
        <p className="pb-2">
          How can struggles in our community be solved through genetic
          engineering (GE) technology?
        </p>
      </Hero>
      {/* ----------------- AT A GLANCE RIBBON ----------------- */}
      <Section className="unit-sections__at-a-glance-section pt-0 pb-0">
        <Container
          fullWidth
          className="unit-sections__at-a-glance-container pt-2 pb-1"
        >
          <div className="course__unit-indicator text-bold">
            <div>AG/ENVIRONMENTAL | UNIT 6</div>
            <Link
              to="/classroom-instruction/ag-environmental/unit-7"
              className="course__unit-indicator-link"
            >
              UNIT 7 <Icon marginLeft name="arrowright" />
            </Link>
          </div>
          <h4 className="mt-0.5 text-bold">At a Glance</h4>
          <Row>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-storyline.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                STORYLINE
              </h3>
              <ul className="course__list course__list-style-none">
                <li className="mr-3">
                  Investigate genetically modified crop products and their
                  impact through a cultural, social, and economic lens.
                </li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-pacing.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                PACING
              </h3>
              <ul className="course__list">
                <li>9 week unit</li>
                <li>45-minute classes</li>
                <li>1 Flex Day Built In</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-framing.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                FRAMING
              </h3>
              <ul className="course__list">
                <li>Standards Aligned</li>
                <li>Driving Question</li>
                <li>Engineering Design Process</li>
                <li>Project Based</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-lesson-plans-labs.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                LESSON PLANS AND LABS
              </h3>
              <ul className="course__list">
                <li>9 Lessons with Lab Elements</li>
                <li>1 Core Lab</li>
                <li>1 Final Project</li>
              </ul>
            </Column>
          </Row>
        </Container>
      </Section>
      {/* ----------------- COURSE CARDS INTRO ----------------- */}
      <Section
        style={{ backgroundImage: `url(${bgImage})` }}
        className="pt-0 pb-3"
      >
        <Container fullWidth className="pt-4 pb-1">
          <Row className="mb-2">
            <Column size={7}>
              <h3 className="text-bold">
                Lesson Plans, Labs and Student Capture Sheets
              </h3>
              <p>
                This unit includes lesson plans, labs, student capture sheets
                that empower educators to engage all students. These lesson
                bundles can be downloaded, for use in the classroom.
              </p>
            </Column>
            {/* Full unit zip file download */}
            <Column offset={1} size={4}>
              <div className="unit_download">
                <a href="/units/Gen-AG-U6.zip" className="button">
                  <Icon marginRight name="download" />
                  Complete Unit
                </a>
                <span className="unit_download">ZIP, 189.4 MB</span>
              </div>
            </Column>
          </Row>
        </Container>
        {/* ----------------- COURSE CARDS ----------------- */}
        <Container fullWidth>
          <Row>
            {/* INTRO */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agAlternativeProteinsIntro.tophat}
                tophatClass={`${agAlternativeProteinsIntro.page}__resource-tophat`}
                ribbon={agAlternativeProteinsIntro.ribbon}
                ribbonClass={`${agAlternativeProteinsIntro.page}__resource-ribbon`}
                img=""
                duration={agAlternativeProteinsIntro.duration}
                subject={agAlternativeProteinsIntro.subject}
                subjectClass={`${agAlternativeProteinsIntro.page}__resource-subject`}
                title={agAlternativeProteinsIntro.title}
                description={agAlternativeProteinsIntro.description}
                actions={agAlternativeProteinsIntro.actions}
              />
            </Column>
            {/* LESSON 1 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agIdentifyingGmos.tophat}
                tophatClass={`${agIdentifyingGmos.page}__resource-tophat`}
                ribbon={agIdentifyingGmos.ribbon}
                ribbonClass={`${agIdentifyingGmos.page}__resource-ribbon`}
                img=""
                duration={agIdentifyingGmos.duration}
                subject={agIdentifyingGmos.subject}
                subjectClass={`${agIdentifyingGmos.page}__resource-subject`}
                title={agIdentifyingGmos.title}
                description={agIdentifyingGmos.description}
                actions={agIdentifyingGmos.actions}
              />
            </Column>
            {/* LESSON 2 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agDetectingGeneticallyEngineeredCrops.tophat}
                tophatClass={`${agDetectingGeneticallyEngineeredCrops.page}__resource-tophat`}
                ribbon={agDetectingGeneticallyEngineeredCrops.ribbon}
                ribbonClass={`${agDetectingGeneticallyEngineeredCrops.page}__resource-ribbon`}
                img=""
                duration={agDetectingGeneticallyEngineeredCrops.duration}
                lab={agDetectingGeneticallyEngineeredCrops.lab}
                subject={agDetectingGeneticallyEngineeredCrops.subject}
                subjectClass={`${agDetectingGeneticallyEngineeredCrops.page}__resource-subject`}
                title={agDetectingGeneticallyEngineeredCrops.title}
                description={agDetectingGeneticallyEngineeredCrops.description}
                actions={agDetectingGeneticallyEngineeredCrops.actions}
              />
            </Column>
            {/* LESSON 3 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agDnaToAlternativeProteins.tophat}
                tophatClass={`${agDnaToAlternativeProteins.page}__resource-tophat`}
                ribbon={agDnaToAlternativeProteins.ribbon}
                ribbonClass={`${agDnaToAlternativeProteins.page}__resource-ribbon`}
                img=""
                duration={agDnaToAlternativeProteins.duration}
                lab={agDnaToAlternativeProteins.lab}
                subject={agDnaToAlternativeProteins.subject}
                subjectClass={`${agDnaToAlternativeProteins.page}__resource-subject`}
                title={agDnaToAlternativeProteins.title}
                description={agDnaToAlternativeProteins.description}
                actions={agDnaToAlternativeProteins.actions}
              />
            </Column>
            {/* INTERACTIVE --- TBD */}
            {/* LESSON 4 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agGoldenRiceCaseStudy.tophat}
                tophatClass={`${agGoldenRiceCaseStudy.page}__resource-tophat`}
                ribbon={agGoldenRiceCaseStudy.ribbon}
                ribbonClass={`${agGoldenRiceCaseStudy.page}__resource-ribbon`}
                img=""
                duration={agGoldenRiceCaseStudy.duration}
                subject={agGoldenRiceCaseStudy.subject}
                subjectClass={`${agGoldenRiceCaseStudy.page}__resource-subject`}
                title={agGoldenRiceCaseStudy.title}
                description={agGoldenRiceCaseStudy.description}
                actions={agGoldenRiceCaseStudy.actions}
              />
            </Column>
            {/* LESSON 5 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agBioethicsDebate.tophat}
                tophatClass={`${agBioethicsDebate.page}__resource-tophat`}
                ribbon={agBioethicsDebate.ribbon}
                ribbonClass={`${agBioethicsDebate.page}__resource-ribbon`}
                img=""
                duration={agBioethicsDebate.duration}
                subject={agBioethicsDebate.subject}
                subjectClass={`${agBioethicsDebate.page}__resource-subject`}
                title={agBioethicsDebate.title}
                description={agBioethicsDebate.description}
                actions={agBioethicsDebate.actions}
              />
            </Column>
            {/* LESSON 6 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agIndustriesWithGeTechnology.tophat}
                tophatClass={`${agIndustriesWithGeTechnology.page}__resource-tophat`}
                ribbon={agIndustriesWithGeTechnology.ribbon}
                ribbonClass={`${agIndustriesWithGeTechnology.page}__resource-ribbon`}
                img=""
                duration={agIndustriesWithGeTechnology.duration}
                subject={agIndustriesWithGeTechnology.subject}
                subjectClass={`${agIndustriesWithGeTechnology.page}__resource-subject`}
                title={agIndustriesWithGeTechnology.title}
                description={agIndustriesWithGeTechnology.description}
                actions={agIndustriesWithGeTechnology.actions}
              />
            </Column>
            {/* LESSON 7 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agProjectRollout.tophat}
                tophatClass={`${agProjectRollout.page}__resource-tophat`}
                ribbon={agProjectRollout.ribbon}
                ribbonClass={`${agProjectRollout.page}__resource-ribbon`}
                img=""
                duration={agProjectRollout.duration}
                subject={agProjectRollout.subject}
                subjectClass={`${agProjectRollout.page}__resource-subject`}
                title={agProjectRollout.title}
                description={agProjectRollout.description}
                actions={agProjectRollout.actions}
              />
            </Column>
            {/* LESSON 8 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agCommunityOutreach.tophat}
                tophatClass={`${agCommunityOutreach.page}__resource-tophat`}
                ribbon={agCommunityOutreach.ribbon}
                ribbonClass={`${agCommunityOutreach.page}__resource-ribbon`}
                img=""
                duration={agCommunityOutreach.duration}
                subject={agCommunityOutreach.subject}
                subjectClass={`${agCommunityOutreach.page}__resource-subject`}
                title={agCommunityOutreach.title}
                description={agCommunityOutreach.description}
                actions={agCommunityOutreach.actions}
              />
            </Column>
            {/* LESSON 9 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agSustainabilityAndManufacturing.tophat}
                tophatClass={`${agSustainabilityAndManufacturing.page}__resource-tophat`}
                ribbon={agSustainabilityAndManufacturing.ribbon}
                ribbonClass={`${agSustainabilityAndManufacturing.page}__resource-ribbon`}
                img=""
                duration={agSustainabilityAndManufacturing.duration}
                subject={agSustainabilityAndManufacturing.subject}
                subjectClass={`${agSustainabilityAndManufacturing.page}__resource-subject`}
                title={agSustainabilityAndManufacturing.title}
                description={agSustainabilityAndManufacturing.description}
                actions={agSustainabilityAndManufacturing.actions}
              />
            </Column>
            {/* LESSON 10*/}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agAdCampaign.tophat}
                tophatClass={`${agAdCampaign.page}__resource-tophat`}
                ribbon={agAdCampaign.ribbon}
                ribbonClass={`${agAdCampaign.page}__resource-ribbon`}
                img=""
                duration={agAdCampaign.duration}
                subject={agAdCampaign.subject}
                subjectClass={`${agAdCampaign.page}__resource-subject`}
                title={agAdCampaign.title}
                description={agAdCampaign.description}
                actions={agAdCampaign.actions}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      {/*---------------------BREADCRUMB NAV-----------------*/}
      <Section className="pb-0 pt-0">
        <SubfooterNav
          current={{
            name: 'Unit 6',
            link: '/classroom-instruction/ag-environmental/unit-6',
          }}
          next={{
            name: 'Unit 7',
            link: '/classroom-instruction/ag-environmental/unit-7',
          }}
          previous={{
            name: 'Unit 5',
            link: '/classroom-instruction/ag-environmental/unit-5',
          }}
        />
      </Section>
    </Layout>
  );
};

export default AgEnvironmentalUnit6;

export const query = graphql`
  query AgenviroUnit6Resources {
    allResourcesJson(
      filter: { page: { eq: "ag-environmental" }, unit: { eq: 6 } }
    ) {
      edges {
        node {
          slug
          unit
          page
          ribbon
          tophat
          img
          subject
          title
          duration
          lab
          description
          actions {
            label
            type
            format
            size
            path
            title
            clickLabel
            clickType
            actions {
              label
              type
              format
              size
              path
              title
              clickLabel
              clickType
              guidSrc
              posterSrc
            }
          }
        }
      }
    }
  }
`;
